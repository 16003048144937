<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Bot Detection</v-toolbar-title>
      <v-btn color="second" dark to="/botDetection" class="ml-2"
        >Bot Detection V1</v-btn
      >
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dates"
            label="Picker in menu"
            prepend-icon="mdi-calendar"
            readonly
            single-line
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dates" locale="tr-TR" range no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="initialize"> OK </v-btn>
        </v-date-picker>
      </v-menu>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="level"
        label="Level >100 or <100"
        single-line
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="bonusTypeSelect"
        :items="bonusType"
        :loading="$store.state.progress"
        item-value="value"
        item-text="id"
        hide-details
        label="Bonus Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-combobox
        :items="userStatus"
        v-model="userReportSelect"
        item-value="key"
        item-text="value"
        hide-details
        label="Status"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
      <v-btn color="red ml-2" dark @click="blockDialog" class="mb-2"
        >Ban User</v-btn
      >
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :options.sync="options"
      hide-default-footer
      v-model="selected"
      show-select
      :items="$store.state.botDetection.botV2.data"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.status="{ item }">
        <div>
          {{ item.status | toUserStatus }}
        </div>
      </template>
      <template v-slot:item.deviceInfo="{ item }">
        <v-tooltip bottom>
          <template
            :allow-overflow="true"
            v-slot:activator="{ on }"
            :dark="true"
            color="#033"
          >
            <v-img
              width="25"
              v-on="on"
              style="float: left"
              :src="`https://pokerface.restplay.com/assets/${item.deviceInfo.os}.png`"
            />
            <v-img
              width="25"
              v-on="on"
              :src="`https://pokerface.restplay.com/assets/${item.platformInfo.snId}.png`"
            />
          </template>

          <span>
            <json-viewer
              name="df"
              :value="item.deviceInfo"
              :expand-depth="5"
              theme="my-awesome-json-theme"
              sort
            ></json-viewer>
            <json-viewer
              name="df"
              :value="item.platformInfo"
              :expand-depth="5"
              theme="my-awesome-json-theme"
              sort
            ></json-viewer>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.played="{ item }">
        <div
          v-for="(itemx, index) in item.played.p"
          :key="index"
          style="width: 500px"
        >
          <b class="yellow--text"> Date:</b> {{ itemx.d | formatOnlyDate }}
          <b class="yellow--text"> Amount:</b> {{ itemx.a | formatMoney }}
          <b class="yellow--text"> Hands:</b> {{ itemx.h | formatMoney }}
          <b class="yellow--text"> Time:</b> {{ itemx.t }}
        </div>
      </template>
      <!-- <template v-slot:item.playerOnline.l="{ item }">
        <div v-if="!item.playerOnline.o">
          <timeago
            :style="
              ` color: ${item.leftTime < 86400 * 3.1 ? 'orange' : '#ff7e7e'}`
            "
            locale="zh_TW"
            :datetime="new Date(item.playerOnline.l * 1000)"
          ></timeago>
        </div>
      </template> -->
      <template v-slot:item.playTime="{ item }">
        <div v-if="item">
          {{ item.playTime | getDateFormat }}
          <span v-if="item.playerOnline">
            /
            <timeago
              locale="zh_TW"
              :datetime="new Date(item.playerOnline.l * 1000)"
            ></timeago>
          </span>
        </div>
      </template>
      <template v-slot:item.locationInfo.country_long="{ item }">
        <div style="display: flex">
          <v-avatar size="36" style="margin-right: 5px; margin-top: 3px">
            <v-img
              v-if="item && item.locationInfo"
              :src="`https://pokerface.restplay.com/flags/${item.locationInfo.country_short.toLocaleLowerCase()}.png`"
            />
          </v-avatar>
          <div v-if="item && item.deviceInfo.ipAdress">
            <span v-if="item.locationInfo">{{
              item.locationInfo.country_long
            }}</span>
            <div>{{ item.deviceInfo.ipAdress.split(",")[0] }}</div>
          </div>
        </div>
      </template>

      <template v-slot:item.chipAmount="{ item }">
        <div v-if="item">
          {{ item.chipAmount | formatMoney }}
        </div>
      </template>
      <template v-slot:item.totalBonus="{ item }">
        <v-tooltip bottom>
          <template
            :allow-overflow="true"
            v-slot:activator="{ on }"
            :dark="true"
            color="#033"
          >
            <span
              v-on="on"
              @click="showUserDetail(item.userId)"
              class="yellow--text"
              >{{ item.totalBonus | formatMoney }}</span
            >
            / {{ item.totalBonusCount | formatMoney }}
          </template>
          <div v-for="(bonus, index) in item.bonusTypes" :key="index">
            {{ bonusType.find((m) => m.value == bonus.key)?.id }}:
            <span style="color: lime">{{ bonus.doc_count | formatMoney }}</span>
          </div>
        </v-tooltip>
      </template>

      <template v-slot:item.createdTime="{ item }">
        <div>{{ new Date(item.createdTime * 1000) | formatOnlyDate }}</div>
      </template>
      <template v-slot:item.id="{ item }">
        <UserDetailCard
          :userId="item.userId"
          :pictureUrl="item.pictureUrl"
          :userName="item.userName"
          :level="item.levelInfo.l"
          :flag="item.flag"
          :isOnline="item.playerOnline.o"
        />
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        :total-visible="20"
        @input="pageClick"
        :length="purchases.totalPage"
      ></v-pagination>
    </div>

    <v-dialog v-model="dialogUser" scrollable max-width="1600">
      <v-card>
        <v-card-title>
          <span class="headline"
            >User Detail
            <span style="color: yellow">{{
              _.sum(
                $store.state.botDetection.userIdDetail.map((m) => m.doc_count)
              ) | formatMoney
            }}</span>
          </span>

          <v-btn
            color="primary ml-2"
            dark
            @click="clicked = !clicked"
            class="mb-2"
            >User Detail</v-btn
          >

          <user-detail-modal-card :clicked="clicked" :userId="userId" />
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="userBonusDetailheaders"
                :items="$store.state.botDetection.userIdDetail"
                :items-per-page="50"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:item.doc_count="{ item }">
                  <div style="color: yellow">{{ item.doc_count }}</div>
                </template>
                <template v-slot:item.key="{ item }">
                  <span
                    @click="editItem('user.deviceInfo.ipAdress', item.key)"
                    >{{ item.key }}</span
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogUser = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import userStatus from "@/lib/userStatus";
import bannedReason from "@/lib/bannedReason";

export default {
  name: "UserQueryCard",

  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },

  props: {
    clicked: {
      type: Boolean,
      default: false,
    },
    val: {
      type: String,
      default: "",
      description: "UserId",
    },
    field: {
      type: String,
      default: "",
      description: "field",
    },
  },
  data() {
    return {
      dates: [
        new Date(Date.now()).addDays(-1).toISOString().substr(0, 10),
        new Date(Date.now()).toISOString().substr(0, 10),
      ],
      dialogUser: false,
      level: null,
      menu: false,
      dialog: false,
      dialog1: false,
      currentPage: 1,
      userStatus: userStatus,
      userReportSelect: { key: -999, value: "Active" },

      purchases: {},
      selected: [],
      bonusType: [...this.$store.state.homes.enumTypes.bonusType],
      bonusTypeSelect: { value: -1, id: "All" },
      options: {},
      fieldsSelect: { id: -1, value: "All" },
      fields: [
        { id: -1, value: "All" },
        {
          id: "user.deviceInfo.deviceUniqueIdentifier",
          value: "user.deviceInfo.deviceUniqueIdentifier",
        },
        { id: "user.deviceInfo.ipAdress", value: "user.deviceInfo.ipAdress" },
      ],
      headers: [
        { text: "Block User", value: "blockUser", sortable: false },

        {
          text: "Id",
          value: "id",
        },
        { text: "Chip Amount", value: "chipAmount" },

        { text: "Platform", value: "deviceInfo" },

        {
          text: "DeviceUniqueIdentifier",
          value: "deviceInfo.deviceUniqueIdentifier",
        },
        { text: "Collected Bonus Chip / Count", value: "totalBonus" },
        { text: "Play Time / Last Online", value: "playTime" },

        { text: "Created Date", value: "createdTime" },
        { text: "Location Info", value: "locationInfo.country_long" },
        { text: "Status", value: "status" },
        // { text: "Last Online", value: "playerOnline.l" },
        { text: "Sig", value: "deviceInfo.sig" },

        { text: "Profit", value: "played" },
      ],
      userReportheaders: [
        {
          text: "Id",
          value: "id",
        },

        {
          text: "DeviceUniqueIdentifier",
          value: "deviceInfo.deviceUniqueIdentifier",
        },
        { text: "Chip Amount", value: "chipAmount" },
        { text: "Play Time", value: "playTime" },

        { text: "Created Date", value: "registerDate" },
        { text: "Location Info", value: "locationInfo.country_long" },
        { text: "Status", value: "status" },
      ],
      userBonusDetailheaders: [
        {
          text: "total",
          align: "left",
          sortable: false,
          value: "doc_count",
        },
        { text: "key", value: "key" },
      ],
      chipAmount: 0,
      isChipDown: false,
      timeleftSelect: null,
      timeleftManuel: null,
      timelefts: [
        { value: "10 Minutes", key: 60 * 10 },
        { value: "1 Hours", key: 60 * 60 },
        { value: "4 Hours", key: 60 * 60 * 4 },
        { value: "12 Hours", key: 60 * 60 * 12 },
        { value: "24 Hours", key: 60 * 60 * 24 },
        { value: "3 Days", key: 60 * 60 * 24 * 3 },
        { value: "7 Days", key: 60 * 60 * 24 * 7 },
        { value: "14 Days", key: 60 * 60 * 24 * 14 },
        { value: "1 Months", key: 60 * 60 * 24 * 30 },
        { value: "3 Months", key: 60 * 60 * 24 * 30 * 3 },
      ],
      bannedReason: bannedReason,
      user: null,
      userId: null,

      bannedReasonSelect: { key: -1, value: "All" },
    };
  },
  // watch: {
  //   async val() {
  //     await this.initialize();
  //   },
  //   options: {
  //     handler() {
  //       this.initialize();
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    pageClick(a) {
      this.currentPage = a;
      // this.initialize();
    },
    async initialize() {
      this.selected = [];
      this.$store.dispatch("botDetection/getBotV2", {
        startDate:
          new Date(
            new Date(this.dates[0]).getTime() +
              new Date().getTimezoneOffset() * 60000
          ) / 1000,
        endDate: new Date(this.dates[1]).addDays(1).getTime() / 1000,
        page: this.currentPage - 1,
        bonusType: this.bonusTypeSelect.value,
        status: this.userReportSelect.key,
        level: this.level,
      });
    },
    async showUserDetail(val) {
      this.userId = val;
      this.$store.dispatch("botDetection/getByUserIdBonus", {
        startDate:
          new Date(
            new Date(this.dates[0]).getTime() +
              new Date().getTimezoneOffset() * 60000
          ) / 1000,
        userId: val,
      });
      this.dialogUser = true;
    },

    async blockDialog() {
      this.dialog1 = true;
    },

    async userBanned() {
      await this.$store.dispatch("userReports/bulkUserBan", {
        ids: this.selected.map((m) => m.id),
        status: this.userReportSelect.key,
        reasonCode: this.bannedReasonSelect.key,
        isChipDown: this.isChipDown,
        timeLeft:
          this.timeleftManuel || this.timeleftSelect
            ? parseInt(
                this.timeleftManuel
                  ? this.timeleftManuel
                  : this.timeleftSelect.key
              )
            : 0,
        chipAmount: parseInt(this.chipAmount),
      });
      this.dialog1 = false;
      this.$store.dispatch("setAlert");
      this.initialize();
    },
  },
  async mounted() {},
};
</script>
